import React, { useEffect, useState } from "react";
import { ApiResponse, Region } from "./Dashboard";
import TotalsTable, { Total } from "./TotalsTable.tsx";
import api from '../../api';
import CircularProgress from "@mui/material/CircularProgress"
import SortButtons, { SortOption } from "./SortButtons.tsx";

interface RegionalProps {
  region: Region;
  onSchoolClicked: (school: Total) => void;
}

interface RegionResponse {
  id: string;
  name: string;
  count: number;
}

const formatRegionalData = (responseData): Array<Total> => {
  return responseData.map((regionResponse: RegionResponse): Total => {
    return {
      label: regionResponse.name,
      count: regionResponse.count,
      value: regionResponse
    };
  }).sort((a: Total, b: Total) => a.label.localeCompare(b.label));
};

const SORT_OPTIONS = [
  { label: "School Name", value: "label" },
  { label: "Pledges", value: "value" }
];

const Regional: React.FC<RegionalProps> = ({ region, onSchoolClicked }) => {
  const [regionalData, setRegionalData] = useState<Array<Total>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [childCount, setChildCount] = useState<number>(0);
  const [schoolCount, setSchoolCount] = useState<number>(0);
  const [sortBy, setSortBy] = useState<SortOption>(SORT_OPTIONS[0])

  useEffect(() => {
    const loadData = async () => {
      const response = await api.get("/pledges", { params: { region: region.code } });
      const responseData = response.data as ApiResponse;
      const data = formatRegionalData(responseData.data);
      setChildCount(responseData.child_count);
      setSchoolCount(responseData.school_count);
      setRegionalData(data);
      setLoading(false);
    };

    loadData();
  }, []);

  if (loading) {
    return (
      <div className="p-16">
        <CircularProgress variant="indeterminate" />
      </div>
    );
  }

  return (
    <div className="w-full p-10 flex flex-col gap-8 items-center">
      <div className="flex flex-row gap-x-5 w-full p-y-10">
        <div className="rounded-xl flex-1 bg-white p-6 flex flex-col items-center gap-8">
          <h3 className="text-red-500 font-bold text-3xl md:text-7xl">{childCount}</h3>
          <p className="uppercase font-bold text-xl md:text-3xl">CHILDREN</p>
        </div>

        <div className="rounded-xl flex-1 bg-white p-6 flex flex-col items-center gap-8">
          <h3 className="text-red-500 font-bold text-3xl md:text-7xl">{schoolCount}</h3>
          <p className="uppercase font-bold text-xl md:text-3xl">SCHOOLS</p>
        </div>
      </div>

      <div className="w-full h-[1px] bg-neutral-300" />

      <p className="font-bold text-xl">Click on your school to see data for each grade</p>

      <SortButtons
        selected={sortBy}
        options={SORT_OPTIONS}
        onSelectionChanged={(selected: SortOption) => setSortBy(selected)} />

      <TotalsTable
        labelHeader="School"
        data={regionalData}
        onLabelClick={onSchoolClicked}
        sortBy={sortBy.value} />
    </div>
  )
};

export default Regional;
