import React from "react";

export interface SortOption {
  label: string;
  value: any;
}

interface SortButtonsProps {
  options: Array<SortOption>;
  selected: SortOption;
  onSelectionChanged: (selected: SortOption) => void;
}

const buttonClasses = (selected: boolean) => {
  if (selected) {
    return "cursor-pointer rounded bg-red-500 text-white py-2 px-4 text-xl";
  } else {
    return "cursor-pointer rounded bg-white py-2 px-4 text-xl";
  }
}

const SortButtons: React.FC<SortButtonsProps> = ({ options, selected, onSelectionChanged }) => {
  return (
    <div className="flex items-center gap-x-4">
      <span>Sort by:</span>
      {options.map(option => (
        <div key={option.label} className={buttonClasses(selected.value === option.value)} onClick={() => onSelectionChanged(option)}>
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default SortButtons;
