import React, { useEffect, useState } from "react";
import TotalsTable, { Total } from "./TotalsTable.tsx";
import api from '../../api';
import CircularProgress from "@mui/material/CircularProgress"
import { ApiResponse, Region } from "./Dashboard.tsx";
import SortButtons, { SortOption } from "./SortButtons.tsx";

interface NationalProps {
  isSummary: boolean;
  onRegionClicked: (region: Total) => void;
}

const PROVINCE_NAMES = {
  AB: "Alberta",
  BC: "British Columbia",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland and Labrador",
  NS: "Nova Scotia",
  NT: "Northwest Territories",
  NU: "Nunavut",
  ON: "Ontario",
  PE: "Prince Edward Island",
  QC: "Quebec",
  SK: "Saskatchewan",
  YT: "Yukon"
};

const formatNationalData = (responseData): Array<Total> => {
  return Object.keys(responseData).map((provinceCode: string): Total => {
    const region: Region = {
      code: provinceCode,
      name: PROVINCE_NAMES[provinceCode.toUpperCase()]
    };

    return { label: region.name, count: responseData[provinceCode], value: region}
  }).sort((a, b) => a.label.localeCompare(b.label));
};

const SORT_OPTIONS = [
  { label: "Region", value: "label" },
  { label: "Pledges", value: "value" }
];

const National: React.FC<NationalProps> = ({ isSummary, onRegionClicked }) => {
  const [nationalData, setNationalData] = useState<Array<Total>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [childCount, setChildCount] = useState<number>(0);
  const [schoolCount, setSchoolCount] = useState<number>(0);
  const [sortBy, setSortBy] = useState<SortOption>(SORT_OPTIONS[0])

  useEffect(() => {
    const loadData = async () => {
      const response = await api.get("/pledges");
      const responseData = response.data as ApiResponse;
      const data = formatNationalData(responseData.data);
      setChildCount(responseData.child_count);
      setSchoolCount(responseData.school_count);
      setNationalData(data);
      setLoading(false);
    };

    loadData();
  }, []);

  if (loading) {
    return (
      <div className="p-16">
        <CircularProgress variant="indeterminate" />
      </div>
    );
  }

  const Summary = () => (
    <div className="w-full p-10 flex flex-col gap-8 items-center">
      <div className="flex flex-row gap-x-5 w-full p-y-10">
        <div className="rounded-xl flex-1 bg-white p-6 flex flex-col items-center gap-8">
          <h3 className="text-red-500 font-bold text-3xl md:text-7xl">{childCount}</h3>
          <p className="uppercase font-bold text-xl md:text-3xl">CHILDREN</p>
        </div>

        <div className="rounded-xl flex-1 bg-white p-6 flex flex-col items-center gap-8">
          <h3 className="text-red-500 font-bold text-3xl md:text-7xl">{schoolCount}</h3>
          <p className="uppercase font-bold text-xl md:text-3xl">SCHOOLS</p>
        </div>
      </div>
    </div>
  );

  if (isSummary) {
    return (
      <div className="w-full p-10 flex flex-col gap-8 items-center">
        <Summary />
      </div>
    );
  }

  return (
    <div className="w-full p-10 flex flex-col gap-8 items-center">
      <Summary />

      <div className="w-full h-[1px] bg-neutral-300" />

      <p className="font-bold text-xl">Select your region below to view data for your area</p>

      <SortButtons
        selected={sortBy}
        options={SORT_OPTIONS}
        onSelectionChanged={(selected: SortOption) => setSortBy(selected)} />

      <TotalsTable
        labelHeader="Region"
        data={nationalData}
        onLabelClick={onRegionClicked}
        sortBy={sortBy.value} />
    </div>
  )
};

export default National;
