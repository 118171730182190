import React, { useState } from "react";
import National from "./National.tsx";
import Regional from "./Regional.tsx";
import IndividualSchool from "./IndividualSchool.tsx";
import styles from "./Dashboard.module.scss";
import { Total } from "./TotalsTable.tsx";
import backArrowImage from '../../assets/images/back-arrow.png';
import { useSearchParams } from "react-router-dom";

type Layout = "national" | "regional" | "individual";

export interface ApiResponse {
  school_count: number;
  child_count: number;
  data: any;
}

export interface Region {
  code: string;
  name: string;
}

export interface School {
  id: string;
  name: string;
}

const Dashboard = () => {
  const [layout, setLayout] = useState<Layout>("national");
  const [region, setRegion] = useState<Region | null>(null);
  const [school, setSchool] = useState<School | null>(null);
  const [searchParams, _setSearchParams] = useSearchParams();
  const isSummary = searchParams.get("summary") === "true";

  const handleRegionClicked = (total: Total) => {
    const region = total.value as Region;
    setRegion(region);
    setLayout("regional");
  }

  const handleSchoolClicked = (total: Total) => {
    const school = total.value as School;
    setSchool(school);
    setLayout("individual");
  }

  /**
   * Returns the appropriate title for the page depending on the layout.
   * For a national layout, the generic "National (Canada)" title will
   * be returned. For regional or individual school layouts, the name
   * of the region/school will be returned
   */
  const getTitle = (): String => {
    if (layout === "national") return "National (Canada)";
    if (layout === "regional") return region!.name;

    return school!.name;
  };

  const lastPageTitle = (): string => {
    if (layout === "regional") return "National (Canada)";

    return region!.name;
  }

  const goBack = (): void => {
    if (layout === "individual") {
      setLayout("regional");
      setSchool(null);
    } else if (layout === "regional") {
      setLayout("national");
      setRegion(null);
    }
  };

  return (<>
    <div className={`flex flex-col items-center ${styles.container}`}>

      {layout !== "national" && (
        <span className="flex items-center gap-x-4 cursor-pointer text-lg my-4" onClick={goBack} >
          <img src={backArrowImage} alt="back" className="h-8"/> Back to {lastPageTitle()}
        </span>
      )}

      {!isSummary && <h2 className="text-3xl font-bold text-center">{ getTitle() }</h2>}

      {layout === "national" && <National isSummary={isSummary} onRegionClicked={handleRegionClicked} />}
      {layout === "regional" && <Regional region={region!} onSchoolClicked={handleSchoolClicked} />}
      {layout === "individual" && <IndividualSchool school={school!} />}
    </div>
  </>);
};

export default Dashboard;
