import React from "react";

export interface Total {
  label: string;
  count: number;
  value?: any;
}

interface TotalsTableProps {
  labelHeader: string;
  data: Array<Total>;
  sortBy?: "value" | "label";
  onLabelClick?: (value: any) => void;
}

const TotalsTable: React.FC<TotalsTableProps> = ({ labelHeader, data, onLabelClick, sortBy = "label" }) => {
  const handleLabelClick = (total: Total) => {
    if (onLabelClick) {
      onLabelClick(total);
    }
  };

  const cellClasses = `border-e border-t text-center py-4 text-xl ${onLabelClick && 'cursor-pointer'}`;
  const sortedData = sortBy === "value"
    ? data.sort((a, b) => -(a.count - b.count))
    : data.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="rounded-xl bg-white w-full">
      <table className="w-full">
        <colgroup>
          <col span={1} width="50%" />
          <col span={1} width="50%" />
        </colgroup>

        <thead>
          <tr>
            <th className="uppercase font-bold text-xl md:text-3xl text-red-500 py-6">{labelHeader}</th>
            <th className="uppercase font-bold text-xl md:text-3xl text-red-500 py-6">Pledges signed</th>
          </tr>
        </thead>

        <tbody>
          {sortedData.map((total: Total) => (
            <tr key={total.label}>
              <td className={cellClasses} onClick={() => handleLabelClick(total)}>{total.label}</td>
              <td className={cellClasses} onClick={() => handleLabelClick(total)}>{total.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TotalsTable;
