import React, { useState } from 'react'
import { Fab } from '@mui/material'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import api from '../../api'

// import NewSchoolForm from '../NewSchoolForm/NewSchoolForm'

interface School {
    id: string;
    address: string;
    name: string;
    city: string;
    province: string;
}

interface Grade {
    name: string;
    value: string;
}

export default function ChildForm({
    childrenData,
    setChildrenData,
    onSubmit,
    addNewChild,
    showValidationError
}) {
    const [hasSearched, setHasSearched] = useState(false);
    const [currentSchoolKey, setCurrentSchoolKey] = useState('1')
    const [nextSchoolKey, setNextSchoolKey] = useState('2')
    const [matchingSchools, setMatchingSchools] = useState([])
    const [currentSchoolTmpLabel, setCurrentSchoolTmpLabel] = useState('')
    const [nextSchoolTmpLabel, setNextSchoolTmpLabel] = useState('')
    const [loadingSchools, setLoadingSchools] = useState(false);
    const grades = [
        { name: 'Pre-Primary/Jr Kindergarten', value: 'JK' },
        { name: 'Primary/Kindergarten', value: 'K' },
        { name: 'Grade 1', value: '1' },
        { name: 'Grade 2', value: '2' },
        { name: 'Grade 3', value: '3' },
        { name: 'Grade 4', value: '4' },
        { name: 'Grade 5', value: '5' },
        { name: 'Grade 6', value: '6' },
        { name: 'Grade 7', value: '7' },
        { name: 'Grade 8', value: '8' },
        { name: 'Grade 9', value: '9' },
        { name: 'Grade 10', value: '10' },
        { name: 'Grade 11', value: '11' },
        { name: 'Grade 12', value: '12' },
    ]

    async function fetchSchools(event: any) {
        setLoadingSchools(true);
        const response = await api.get('/schools', {
            params: { name: event.target.value }
        })
        setMatchingSchools(response.data)
        setLoadingSchools(false);
        setHasSearched(true);
    }

    const setChildProperty = (property, value) => {
        let childObj = childrenData[0]
        childObj = {
            ...childObj,
            [property]: value,
        }
        setChildrenData(
            [childObj].concat(childrenData.slice(1))
        )

        return childObj;
    }

    const noOptionsLabel = () => {
        if (!hasSearched) return "Start typing the name of the school to search for it";

        return "No schools found";
    }

    function validateName(name) {
        return name.length >= 1;
    }

    function validateChildInput(childObj) {
        if (
            !(
                validateName(childObj.first_name) &&
                validateName(childObj.last_name)
            )
        ) {
            showValidationError('Childs name is invalid. First and last name must be at least 1 character long');
            return false;
        }

        const validGrades = grades.map((grade: Grade) => grade.value);
        if (!validGrades.find(grade => childObj.grade === grade)) {
            showValidationError('Grade is invalid');
        }

        if (childObj.school_id.length === 0) {
            showValidationError('You must specify the current school');
            return false;
        }

        showValidationError(null);
        return true
    }

    const [isValidInput, setIsValidInput] = useState(true);

    function addNewSchoolToChild(addedSchool, whichSchool) {
        alert("OH YEAH");
        let childObj = childrenData[0]
        if (whichSchool === 'current') {
            setChildProperty('school_id', addedSchool.id);
            setCurrentSchoolTmpLabel(
                `${addedSchool.name}, ${addedSchool.city}, ${addedSchool.province}`
            )
        } else {
            setChildProperty('next_school_id', addedSchool.id);
            setNextSchoolTmpLabel(
                `${addedSchool.name}, ${addedSchool.city}, ${addedSchool.province}`
            )
        }
    }

    function validateAndSubmit() {
        const childObj = childrenData[0]
        const result = validateChildInput(childObj)
        if (result !== isValidInput) setIsValidInput(result)

        if (result) {
            onSubmit();
        }
    }

    return (
        <div className="form__fields">
            <h2 className="form__subtitle text-[24px]  font-bold text-[#2e4049]">
                Student Pledging To Wait
            </h2>
            <label className="form__input-label">
                Date
                <input
                    className="form__input-field"
                    type="text"
                    readOnly
                    value={new Date(Date.now()).toLocaleString().split(',')[0]}
                />
            </label>
            <label className="form__input-label">
                Student First Name
                <input
                    className="form__input-field"
                    type="text"
                    placeholder="First Name (required)"
                    value={childrenData[0].first_name}
                    onChange={(e) => {
                        const childObj = setChildProperty('first_name', e.target.value);
                        // let result = validateChildInput(childObj)
                        // if (result !== isValidInput) setIsValidInput(result)
                    }}
                />
            </label>

            <label className="form__input-label">
                Student Last Name
                <input
                    className="form__input-field"
                    type="text"
                    placeholder="Last Name (required)"
                    value={childrenData[0].last_name}
                    onChange={(e) => {
                        setChildProperty('last_name', e.target.value);
                        // let result = validateChildInput(childObj)
                        // if (result !== isValidInput) setIsValidInput(result)
                    }}
                />
            </label>
            <label className="form__input-label">
                Grade in 2024-2025 School Year
                <Autocomplete
                    disablePortal
                    options={grades}
                    getOptionLabel={(option: Grade) => {
                        return `${option['name']}`
                    }}
                    onChange={(_, value) => {
                        const grade = value as Grade;
                        setChildProperty('grade', grade.value);
                    }}
                    sx={{ marginBottom: 2 }}
                    renderInput={(params) => <TextField {...params} />}
                    isOptionEqualToValue={(opt: Grade, value: Grade) => opt.value === value.value}
                />
            </label>

            {/* <NewSchoolForm
                addNewSchoolToChild={addNewSchoolToChild}
                whichSchool={'current'}
                hideAddNewSchool={currentSchoolTmpLabel !== ''}
                showValidationError={showValidationError}
            /> */}

            <Autocomplete
                key={currentSchoolKey}
                disablePortal
                loading={loadingSchools}
                id="combo-box-demo"
                options={matchingSchools}
                getOptionLabel={(option) => {
                    return `${option['name']}, ${option['city']}, ${option['province']}`
                }}
                noOptionsText={noOptionsLabel()}
                onChange={(_, value) => {
                    const school = value as School | null;
                    const schoolId = school?.id || '';

                    const childObj = setChildProperty('school_id', schoolId);
                    let result = validateChildInput(childObj)
                    if (result !== isValidInput) setIsValidInput(result)
                }}
                sx={{ marginBottom: 2 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        helperText="Start typing the name of the school to search for it"
                        label={
                            currentSchoolTmpLabel
                                ? currentSchoolTmpLabel
                                : "Your Child's Current School"
                        }
                        onChange={(e) => {
                            setCurrentSchoolTmpLabel('')
                            fetchSchools(e)
                        }}
                    />
                )}
            />

            {/* <NewSchoolForm
                addNewSchoolToChild={addNewSchoolToChild}
                whichSchool={'next'}
                hideAddNewSchool={nextSchoolTmpLabel !== ''}
                showValidationError={showValidationError}
            /> */}

            <Autocomplete
                key={nextSchoolKey}
                disablePortal
                id="combo-box-demo"
                options={matchingSchools}
                loading={loadingSchools}
                getOptionLabel={(option) => {
                    return `${option['name']}, ${option['city']}, ${option['province']}`
                }}
                noOptionsText={noOptionsLabel()}
                onChange={(_, value) => {
                    const school = value as School | null;
                    const schoolId = school?.id || '';

                    const childObj = setChildProperty('next_school_id', schoolId);
                    let result = validateChildInput(childObj)
                    if (result !== isValidInput) setIsValidInput(result)
                }}
                sx={{ marginBottom: 3 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        helperText="Start typing the name of the school to search for it"
                        label={
                            nextSchoolTmpLabel
                                ? nextSchoolTmpLabel
                                : "Your Child's Next School"
                        }
                        onChange={(e) => {
                            setNextSchoolTmpLabel('')
                            fetchSchools(e)
                        }}
                    />
                )}
            />

            <p className="mb-8">If you can't find your school above, please <a href="mailto:info@unpluggedcanada.com?subject=Missing%20school%20request" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">get in touch</a> so we can add it</p>

            <div className="form__button">
                <Fab
                    variant="extended"
                    onClick={() => {
                        addNewChild()
                        setMatchingSchools([])
                        setIsValidInput(false)
                        setCurrentSchoolKey(currentSchoolKey + '1')
                        setNextSchoolKey(nextSchoolKey + '2')
                    }}
                >
                    Add Other Child
                </Fab>
                <Fab
                    style={{
                        color: 'white',
                        width: '10rem',
                        marginLeft: '1rem',
                    }}
                    color="primary"
                    variant="extended"
                    onClick={validateAndSubmit}
                >
                    Submit
                </Fab>
            </div>
        </div>
    )
}
