import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import theme from './styles/theme.tsx'
import React from 'react'
import IFrameForm from './pages/IFrameForm/IFrameForm.tsx';
// import Home from './pages/Home/Home.tsx'
// import Pledge from './pages/Pledge/Pledge.tsx'
// import Resources from './pages/Resources.tsx'
// import Research from './pages/Research.tsx'
// import Ambassadors from './pages/Ambassadors.jsx'
// import FAQs from './pages/FAQs.tsx'
// import Government from './pages/Government.tsx'
// import Communities from './pages/Communities.tsx'
// import Privacy from './pages/Privacy.tsx'
import Dashboard from './pages/Dashboard/Dashboard.tsx'
import Redirect from './pages/Redirect.tsx'

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    {/* <Route path="/" element={<Home />} /> */}
                    {/* <Route path="/pledge" element={<Pledge />} /> */}
                    {/* <Route path="/resources" element={<Resources />} /> */}
                    {/* <Route path="/research" element={<Research />} /> */}
                    {/* <Route path="/ambassadors" element={<Ambassadors />} /> */}
                    {/* <Route path="/faqs" element={<FAQs />} /> */}
                    {/* <Route path="/government" element={<Government />} /> */}
                    {/* <Route path="/communities" element={<Communities />} /> */}
                    {/* <Route path="/privacy-policy" element={<Privacy />} /> */}

                    <Route path="/iframe" element={<IFrameForm />} />
                    <Route path="/dashboard" element={<Dashboard />} />

                    <Route path="*" element={<Redirect />} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default App
